<template>
    <div
        class="c-nal-footer u-primary-color__text"
        :class="{ 'c-nal-footer--cp': onClientPortal }"
    >

        <div class="c-nal-footer__powered">
            <span class="c-nal-footer__powered-text"> {{ trans('common.powered', {}, 'en') }} </span>
            <img
                class="c-nal-footer__powered-logo"
                src="/images/ezekia/Ezekia-logo-final2.png"
            >
        </div>

        <div class="c-nal-footer__copyright">
            &copy;
            {{ year }}
            &nbsp;
            Ezekia
        </div>

        <div class="c-nal-footer__company">
            {{ trans('common.company-info', {}, 'en') }}
        </div>
        <div class="c-nal-footer__company">
            {{ trans('common.address', {}, 'en') }}
        </div>
        <div class="c-nal-footer__terms">

            <div
                v-if="!onClientPortal"
                class="c-nal-footer__privacy-policy"
            >
                <a
                    v-if="!isRoute"
                    :href="baseUrl + '/terms/privacy-policy'"
                >
                    {{ ( trans('common.privacy-policy', {}, 'en')) }}
                </a>

                <router-link
                    v-else
                    :to="{ name: 'terms', params: { tab: 'privacy-policy' } }"
                    class="u-hover-text"
                >
                    {{ ( trans('common.privacy-policy', {}, 'en')) }}
                </router-link>

                &nbsp;&nbsp;|&nbsp;&nbsp;

            </div>

            <div class="c-nal-footer__cookie">
                <a
                    v-if="!isRoute && !onClientPortal"
                    :href="baseUrl + '/terms/cookie-policy'"
                >
                    {{ ( trans('common.cookie-policy', {}, 'en')) }}
                </a>

                <router-link
                    v-else
                    :to="{ name: 'terms', params: { tab: 'cookie-policy' } }"
                    class="u-hover-text"
                >
                    {{ ( trans('common.cookie-policy', {}, 'en')) }}
                </router-link>
            </div>

            <div
                v-if="!onClientPortal"
                class="c-nal-footer__subscription-agreement"
            >
                &nbsp;&nbsp;|&nbsp;&nbsp;

                <a
                    v-if="!isRoute"
                    :href="baseUrl + '/terms/subscription-agreement'"
                >
                    {{ ( trans('common.subscription-agreement', {}, 'en')) }}
                </a>

                <router-link
                    v-else
                    :to="{ name: 'terms', params: { tab: 'subscription-agreement' } }"
                    class="u-hover-text"
                >
                    {{ ( trans('common.subscription-agreement', {}, 'en')) }}
                </router-link>
            </div>

        </div>

        <div class="c-nal-footer__certificates">
            <qms-certificate></qms-certificate>
        </div>

        <div class="c-nal-footer__language">
            <a
                class="u-hover-text"
                href="/locale/en"
            >
                EN
            </a>
            |
            <a
                class="u-hover-text"
                href="/locale/de"
            >
                DE
            </a>
            |
            <a
                class="u-hover-text"
                href="/locale/es"
            >
                ES
            </a>
            |
            <a
                class="u-hover-text"
                href="/locale/fr"
            >
                FR
            </a>
            |
            <a
                class="u-hover-text"
                href="/locale/pt-BR"
            >
                PT-BR
            </a>
        </div>
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue';
import qmsCertificate from '../../home-ezekia/vue-components/common/qms-certificate.vue';

export default {
    name: 'NalFooter',
    components: {
        qmsCertificate,
    },
    props: {
        onClientPortal: Boolean,
    },
    data() {
        return {
            baseUrl: nal.baseUrl,
        };
    },
    computed: {
        year() {
            return (new Date).getFullYear();
        },
        isFirm() {
            return this.nal.user && this.nal.user.role === 'search';
        },
        isRoute() {
            const path = location.pathname;
            return path === '/' || path === 'home' || path === '';
        },
    },
    methods: {
        cookieModal() {
            this.createModal({
                data: {
                    component: defineAsyncComponent(() => import('@js/vue-components/terms/cookie-wrap.vue')),
                    style: { width: '700px', height: '700px' },
                    data: {
                        executives: false,
                    },
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>

.c-nal-footer {
    font-size: 14px;
    margin: 50px 0 30px 0;
    text-align: center;

    &--cp {
        margin: 0;
        padding: 30px 0 10px;

    }

    &__powered {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 4px;

        &-text {
            font-weight: 600;
            margin-right: 10px;
        }

        &-logo {
            height: 40px;
            width: auto;
        }
    }

    &__copyright {
        margin-bottom: 5px;
    }

    &__company {
        font-size: 10px;
    }

    &__terms {
        display: flex;
        font-size: 10px;
        justify-content: center;
        margin: 5px 0;
        text-transform: uppercase;
    }

    &__social {
        margin: 8px 0;

        &-icon {
            border-radius: 50%;
            border-style: solid;
            border-width: 2px;
            display: inline-block;
            font-size: 12px;
            height: 26px;
            line-height: 22px;
            width: 26px;

            &:not(:first-child) {
                margin-left: 3px;
            }
        }
    }

    &__language {
        font-size: 12px;
    }

    &__certificates {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

@media (max-width: 450px) {
    .c-nal-footer {
        &__powered {
            flex-direction: column;
            margin-bottom: 6px;

            &-text {
                margin: 0 0 6px 0;
            }
        }
    }
}

</style>
